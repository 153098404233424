import service from "../../utils/request";

//24小时收入统计
export const getHoursParkCharge=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getHoursParkCharge',
        params:{
            fkPlatTenantId
        }
    })
}