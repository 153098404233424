<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="jc-center">
          <img src="../assets/images/pic1.png" style="width: 100%;" alt="" srcset="">
        </div>

        <!-- 第二行 -->
        <div class="secondLineBox">
          <div class="firstPartBox">今日进出场车辆</div>
          <div class="secondPartBox">
            <div class="secondPartBox-left">
              <div class="secondPartBox-leftText">总进场数</div>
              <div class="secondPartBox-leftData">
                <!-- <dv-digital-flop :config="config1" style="width:140px;height:40px;" /> -->
                <span v-for="(item, index) in number1" :key="index" class="led-font">
                  {{ item }}
                </span>
              </div>
              <div class="secondPartBox-leftLiang">辆</div>
            </div>
            <div class="secondPartBox-right">
              <div class="secondPartBox-rightText">总出场数</div>
              <div class="secondPartBox-rightData">
                <!-- <dv-digital-flop :config="config2" style="width:140px;height:40px;" /> -->
                <span v-for="(item, index) in number2" :key="index" class="led-font">
                  {{ item }}
                </span>
              </div>
              <div class="secondPartBox-rightLiang">辆</div>
            </div>
          </div>
          <div class="thirdPartBox">今日车场收入</div>
        </div>

        <!-- 第三行数据 -->
        <div class="thirdMainBox">
          <div class="leftMainBox">
            <mainLeftFather></mainLeftFather>
          </div>
          <div class="centerMainBox">
            <mainCenterFather></mainCenterFather>
          </div>
          <div class="rightMainBox">
            <mainRightFather></mainRightFather>
          </div>
        </div>

        <!--第四行数据-->
        <div class="fouthBottomBox">
          <bottom-left></bottom-left>
          <bottom-center></bottom-center>
          <bottom-right></bottom-right>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import mainLeftFather from "../components/echart/mainLeft/mainLeftFather.vue";
import mainCenterFather from "../components/echart/mainCenter/mainCenterFather.vue";
import mainRightFather from "../components/echart/mainRight/mainRightFather.vue";
import bottomLeft from "../components/echart/bottomLeft/bottomLeft.vue";
import bottomCenter from "../components/echart/bottomCenter/bottomCenter.vue";
import bottomRight from "../components/echart/bottomRight/bottomRight.vue";
import { getParkMonitor } from "../../src/api/getParkMonitor/getParkMonitor.js";

export default {
  mixins: [drawMixin],
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      loading: true,
      config1: {
        number: [0],
        content: "{nt}",
      },
      config2: {
        number: [0],
        content: "{nt}",
      },
      number1: [],
      number2: []
    };
  },
  components: {
    mainLeftFather,
    mainCenterFather,
    mainRightFather,
    bottomLeft,
    bottomCenter,
    bottomRight,
  },
  mounted() {
    this.cancelLoading();
  },
  created() {
    this.getParkMonitorList();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async getParkMonitorList() {
      const res = await getParkMonitor(this.fkPlatTenantId);
      console.log("总进出场数", res.data.recordNums);
      const totalInNums =
        res.data.recordNums.totalInNums == null
          ? 0
          : res.data.recordNums.totalInNums;
      const totalOutNums =
        res.data.recordNums.totalOutNums == null
          ? 0
          : res.data.recordNums.totalOutNums;
      this.$set(this.config1, "number", [Number(totalInNums)]);
      this.config1 = { ...this.config1 };
      this.$set(this.config2, "number", [Number(totalOutNums)]);
      this.config2 = { ...this.config2 };

      this.number1 = (Number(totalInNums) + '').split('');
      this.number2 = (Number(totalOutNums) + '').split('');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.firstPartBox,
.thirdPartBox {
  width: 24%;
  height: 46px;
  line-height: 48px;
  text-align: center;
  background-image: url(../assets/images/pic4.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  letter-spacing: 1px;
	color: #17caf0;
  font-size: 22px;
  font-family: '黑体';
  font-weight: bolder;
}
.centerMainBox {
  background-image: url(../assets/images/pic5.png);
  background-size: 100% 100%;
}
span.led-font {
  width: 30px;
	height: 38px;
  font-size: 41px;
  text-align: center;
  line-height: 38px;
}
</style>
