import service from "../../utils/request";

//24小时进出场流量
export const dataVisualizationTodayRecordFlow=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/dataVisualizationTodayRecordFlow',
        params:{
            fkPlatTenantId
        }
    })
}