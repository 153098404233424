<template>
  <div class="topChild">
    <div class="dv-bor-13">
      <div class="mainBox">
        <div class="topPart">
          <div class="topPartLeft">
            <div class="topPartLeftTitle">进场车辆</div>
            
            
            <div class="topPartLeftData">
              {{ number1 }}
              <!-- <dv-digital-flop :config="config1" style="width:100px;" /> -->
            </div>
          </div>
          <div class="topPartRight">
            <div class="topPartRightTitle">出场车辆</div>
            <div class="topPartRightData">
              {{ number2 }}
              <!-- <dv-digital-flop :config="config2" style="width:100px;" /> -->
            </div>
          </div>
        </div>
        <div class="bottomPart">
          <dv-scroll-board :config="config3" class="dv-scr-bo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTodayRecordInOut } from "../../../api/getTodayRecordInOut/getTodayRecordInOut.js";
export default {
  name: "topChild",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      number1: 0,
      number2: 0,
      config1: {
        number: [0],
        content: "{nt}",
      },
      config2: {
        number: [0],
        content: "{nt}",
      },
      config3: {
        rowNum:4,
        data: [],
        align:['center','center','center','center'],
        waitTime: 2000
      },
    };
  },
  created() {
    this.getTodayRecordInOutList();
  },
  methods: {
    async getTodayRecordInOutList() {
      const res = await getTodayRecordInOut(this.fkPlatTenantId);
      console.log("今日进出场车辆", res.data.recordNums);
      console.log("今日进出场车辆", res);
      this.$set(this.config1, "number", [
        Number(res.data.recordNums.totalInNums),
      ]);
      this.config1 = { ...this.config1 };
      this.$set(this.config2, "number", [
        Number(res.data.recordNums.totalOutNums),
      ]);
      this.config2 = { ...this.config2 };
      this.number1 = Number(res.data.recordNums.totalInNums);
      this.number2 = Number(res.data.recordNums.totalOutNums);

      let todayInOutCar = [];
      let todayCar = [];
      for (var i = 0; i < res.data.list.length; i++) {
        todayCar = [];
        if(res.data.list[i].recordType == '进场') {
          todayCar.push(
            `<div class="in-icon" style="background-image: url(/image/in-icon.png); width: 20px; height: 100%; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;"></div>`
          );
        } else if(res.data.list[i].recordType == '出场') {
          todayCar.push(
            `<div class="in-icon" style="background-image: url(/image/out-icon.png); width: 20px; height: 100%; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;"></div>`
          );
        }
        
        todayCar.push(
          `<span style="font-size:14px;">${res.data.list[i].lotName}</span>`
        );
        todayCar.push(
          `<span style="font-size:14px;">${res.data.list[i].carNum}</span>`
        );
        todayCar.push(
          `<span style="font-size:14px;">${res.data.list[i].recordTime.slice(
            11
          )}</span>`
        );
        todayInOutCar.push(todayCar);
      }
      this.$set(this.config3, "data", todayInOutCar);
      this.config3 = { ...this.config3 };
    },
  },
};
</script>

<style lang="scss" scoped>
.topChild {
  .dv-bor-13 {
    height: 315px;
    // margin-top: 10px;
    // margin-bottom: 10px;
    padding: 20px;
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .mainBox {
      height: 270px;
      display: flex;
      flex-direction: column;
      .topPart {
        height: 48%;
        padding: 20px 50px 0 50px;
        display: flex;
        justify-content: space-between;
        .topPartLeft,
        .topPartRight {
          width: 50%;
          padding: 20px 0 10px 0;
          text-align: center;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // justify-content: space-between;
          font-size: 22px;
          letter-spacing: 3px;
          font-weight: 700;
          color: #00caf0;
        }
      }
      .bottomPart {
        height: 52%;
        .dv-scr-bo {
          margin-bottom: 40px;
        }
      }
    }
  }
}
.topPartLeftData,.topPartRightData {
  margin-top: 20px;
  text-shadow: 0 0 10px #078792;	//设置发光效果
  font-size: 35px;
}
</style>
