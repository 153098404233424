<template>
  <div class="bottomChooseVideo">
    <a-modal v-model="visible" title="请选择监控" @ok="handleOk" @cancel="handleCancel" okText="确认" cancelText="取消"
      class="myModal" mask>
      <a-tree
        v-model="checkedKeys"
        checkable
        :auto-expand-parent="true"
        :tree-data="basicData"
        :check-strictly="true"
      />
    </a-modal>
  </div>
</template>

<script>
import { getTreeList } from "../../../api/chooseVideo/chooseVideo.js";
import { getDeviceStartProduction } from "../../../api/getDeviceStartProduction/getDeviceStartProduction.js"


function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

export default {
  name: "bottomChooseVideo",
  data() {
    return {
      visible: false,
      disabled: false,
      targetKeys: [],
      dataSource: [],
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      basicData: [],
      deviceId: 'ff8080817d97e30a017d97e386160002',
      locale: {
        itemUnit: '项',
        itemsUnit: '项',
        notFoundContent: '暂无数据'
      },
      checkedKeys: {
        checked: []
      }
    };
  },

  created() {
    this.getTreeList();
    // this.getDeviceStartProductionList()

  },
  methods: {
    showModle() {
      this.visible = true;
    },
    // 获取树形结构数据
    async getTreeList() {
      const res = await getTreeList(this.fkPlatTenantId);
      res.data.result.forEach((item) => {
        //let arr=[]
        let obj = {};
        obj.key = item.key;
        obj.title = item.title;
        obj.checkable = false;
        obj.children = [];
        item.children.forEach((citem) => {
          let objChild = {};
          objChild.key = citem.key;
          objChild.title = citem.title;
          obj.children.push(objChild);
        });
        this.basicData.push(obj);
      });
      this.checkedKeys.checked = ['ff8080818576611101857680ac210271', 'ff808081857661110185768104d10277']
      this.handleOk();
      // setInterval(() => {
      //   this.handleOk();
      // }, 30000)
      console.log("chooseVideo", this.dataSource);
    },
    onChange(targetKeys) {
      if (targetKeys.length > 1) {
        this.disabled = true;
      }
      this.targetKeys = targetKeys;
      console.log("Target Keys:", targetKeys);
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    handleOk() {
      if (this.checkedKeys.checked.length == 1) {
        getDeviceStartProduction(this.checkedKeys.checked[0]).then(res => {
          let arr1 = []
          if (res.data.result !== null) {
            console.log('视频信息', res.data.result)
            arr1.push(res.data.result.fmp4.url)
            this.$emit('videoUrl', arr1)
            this.visible = false
            console.log(arr1)
          } else {
            this.$message.error('未找到视频地址')
          }

        })
      } else {
        let arr2 = []
        let arr3 = this.checkedKeys.checked;
        arr3.forEach(item => {
          getDeviceStartProduction(item).then(res => {
            if (res.data.result !== null) {
              arr2.push(res.data.result.fmp4.url)
              this.visible = false
              this.$emit('videoUrl', arr2)
              console.log(arr2)
            } else {
              this.$message.error('未找到视频地址');
            }
          })
        })

      }
      //let reVideoKeyArr=this.targetKeys.reverse()

    },
    async getDeviceStartProductionList() {
      const res = await getDeviceStartProduction(this.deviceId)
      console.log(res)
      return res.data.result
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.tree-transfer .ant-transfer-list:first-child {
  width: 50%;
  flex: none;
}

.myModal {
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        background-color: red;
      }
    }
  }
}
</style>