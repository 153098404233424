<template>
  <div class="mainLeftFather">
    <top-child></top-child>
    <bottom-child></bottom-child>
  </div>
</template>

<script>
import topChild from "./topChild.vue";
import bottomChild from "./bottomChild.vue";
export default {
  name: "mainLeftFather",
  components: {
    topChild,
    bottomChild,
  },
};
</script>

<style lang="scss" scoped>
.mainLeftFather {
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding: 10px;
  // justify-content: space-between;
}
</style>
