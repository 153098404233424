import service from "../../utils/request";

//今日车场收入
export const getTodayParkCharge=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getTodayParkCharge',
        params:{
            fkPlatTenantId
        }
    })
}