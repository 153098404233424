import axios from 'axios'

const service = axios.create({
    //baseURL:'http://192.168.1.127.0.0.1:8088/',
    baseURL:'https://www.wyzhengxin.cn',//测试环境
    //baseURL:'http://192.168.1.181:8088/',
    timeout:1800000
})

service.interceptors.request.use((config)=>{
    return config
})


export default service
    