<template>
  <div class="bottomCenter">
    <div class="dv-bor-box">
      <div class="topText" @click="chooseVideo">请选择监控</div>
      <div class="imgBox">
        <template v-if="videoUrlArr.length > 0">
          <div v-for="(item, index) in videoUrlArr" :key="index" style="height: 160px;" :style="{ 'width': `calc( 100% / ${ videoUrlArr.length } - 20px )` }">
            <video
              loop
              id="videoElementOne"
              controls
              autoplay
              muted
              style="height: 100%; width: 100%;"
              :src="item"
            >
              浏览器不支持
            </video>
          </div>
        </template>  
        
        <!-- <div class="FirstImgBox" v-if="videoUrlArr.length > 0">
          <video
            id="videoElementOne"
            controls
            autoplay
            muted
            style="height: 100%;"
            :src="videoUrlArr[0]"
          >
            浏览器不支持
          </video>
        </div>
        <div class="SecondImgBox" v-if="videoUrlArr.length == 2">
          <video
            id="videoElementTwo"
            controls
            autoplay
            muted
            style="height: 100%;"
            :src="videoUrlArr[1]"
          >
            浏览器不支持
          </video>
        </div> -->
        <div class="bgDiv" v-if="videoUrlArr.length == 0">
          <div class="noVideo">
            <img src="@/assets/大屏切图/noVideo.png" />
            <div style="margin-top: 7px; font-weight: 700; color: white">
              暂无监控
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottomChooseVideo
      ref="chooseVideoModle"
      @videoUrl="getVideoUrl"
    ></bottomChooseVideo>
  </div>
</template>

<script>
import bottomChooseVideo from "./bottomChooseVideo.vue";
import flvjs from "flv.js";
export default {
  name: "bottomCenter",
  data() {
    return {
      videoUrlArr: [
        // 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4', 
        // 'http://vjs.zencdn.net/v/oceans.mp4', 
        // 'https://media.w3.org/2010/05/sintel/trailer.mp4', 
        // 'http://mirror.aarnet.edu.au/pub/TED-talks/911Mothers_2010W-480p.mp4'
      ],
      player1: "",
      player2: "",
    };
  },
  components: {
    bottomChooseVideo,
  },

  watch: {
    // videoUrlArr(val) {
    //   console.log('子组件传来的视频地址',val)
    //   setTimeout(() => {
    //     this.initFlv(val)
    //   }, 500);
    // },
  },
  methods: {
    chooseVideo() {
      this.$refs.chooseVideoModle.showModle();
    },
    getVideoUrl(val) {
      this.videoUrlArr = val;
      console.log("视频地址", val);
    },
    initFlv(val) {
      if (val.length == 1) {
        if (flvjs.isSupported()) {
          this.player1 = flvjs.createPlayer({
            type: "flv",
            url: val[0],
            isLive: true, //是否为直播流
            hasAudio: false, //音频流
          });
        }
        var video1 = document.getElementById("videoElementOne");
        this.player1.attachMediaElement(video1);
        this.player1.load();
        this.player1.play();
      } else if (val.length == 2) {
        if (flvjs.isSupported()) {
          this.player1 = flvjs.createPlayer({
            type: "flv",
            url: val[0],
            isLive: true, //是否为直播流
            hasAudio: false, //音频流
          });
          this.player2 = flvjs.createPlayer({
            type: "flv",
            url: val[1],
            isLive: true, //是否为直播流
            hasAudio: false, //音频流
          });
        }
        var video = document.getElementById("videoElementOne");
        this.player1.attachMediaElement(video);
        this.player1.load();
        this.player1.play();

        var video2 = document.getElementById("videoElementTwo");
        this.player2.attachMediaElement(video2);
        this.player2.load();
        this.player2.play();
      }
    },
  },
  //在销毁生命周期内销毁组件
  destroyed() {
    this.player1.pause();
    this.player2.pause();

    this.player1.unload();
    this.player2.unload();

    this.player1.detachMediaElement();
    this.player2.detachMediaElement();

    this.player1.destroy();
    this.player2.destroy();

    this.player1 = null;
    this.player2 = null;
  },
};
</script>

<style scoped lang="scss">
.bottomCenter {
  width: 48%;

  .dv-bor-box {
    height: 260px;
    margin-bottom: 5px;
    padding: 30px;
    background-image: url(../../../assets/images/pic2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .topText {
      width: 140px;
      height: 30px;
      border: 2px solid #006aa1;
      line-height: 30px;
      text-align: center;
      margin-left: 15px;
      background-color: #004774;
      color: #4cccff;
      font-weight: 700;
      border-radius: 5px;
      cursor: pointer;
    }
    .imgBox {
      margin-left: 10px;
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      .FirstImgBox,
      .SecondImgBox {
        width: 40%;
        height: 150px;
        // .video-player {
        //   width: 100%;
        //   height: 100%;
        //   #vjs_video_3{
        //     width: 100%;
        //     height: 100% !important;
        //     video{
        //       height: 128px !important;
        //     }
        //   }
        // }
      }
      .bgDiv {
          width: 330px;
          height: 150px;
          background-color: #1d5acc;
          position: relative;
          .noVideo {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
    }
  }
}
</style>
