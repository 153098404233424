import service from "../../utils/request";

//城市车场监测与总进出场数
export const getDeviceStartProduction=(deviceId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getDeviceStartProduction',
        params:{
            deviceId
        }
    })
}