<template>
  <div class="mainCenterFather">
    <div class="topPart">
      城市车场监测
    </div>

    <div class="iconTitlePart">
      <div class="firstIconBox">
        <img class="firstIcon" src="@/assets/大屏切图/总车位@2x.png" />
        <div class="firstText">总车位数</div>
      </div>
      <div class="secondIconBox">
        <img class="secondIcon" src="@/assets/大屏切图/空余车位@2x.png" />
        <div class="secondText">空余车位</div>
      </div>
      <div class="thirdIconBox">
        <img class="thirdIcon" src="@/assets/大屏切图/在场车位@2x.png" />
        <div class="thirdText">在场总数</div>
      </div>
      <div class="fouthIconBox">
        <img class="fouthIcon" src="@/assets/大屏切图/泊位车位@2x.png" />
        <div class="fouthText">泊位占用率</div>
      </div>
    </div>

    <a-carousel :autoplay="false" style="height: 500px" class="carous">
      <div v-for="(itemi, index) in totalArr" :key="index" class="cartContainer">
        <main-center-child v-for="item in itemi" :key="item.id" :parkLotName="item.parkLotName"
          :totalSpaces="item.totalSpaces" :remainSpaces="item.remainSpaces" :floorVehicleNums="item.floorVehicleNums"
          :parkingRate="item.parkingRate"></main-center-child>
      </div>
    </a-carousel>
  </div>
</template>

<script>
import { getParkMonitor } from "../../../api/getParkMonitor/getParkMonitor.js";
import mainCenterChild from "./mainCenterChild.vue";
export default {
  name: "mainCenterFather",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      list: [], //城市车场数组
      totalArr: [],
      totalArrLength: "",
    };
  },
  components: {
    mainCenterChild,
  },
  created() {
    this.getParkMonitorList();
  },
  methods: {
    async getParkMonitorList() {
      const res = await getParkMonitor(this.fkPlatTenantId);
      console.log("城市车场监测", res);
      this.list = res.data.list;
      let pageNum = Math.ceil(res.data.list.length / 9);
      for (var i = 0; i < pageNum; i++) {
        let index = 0;
        let arr = res.data.list.splice(index, 9);
        arr.forEach((item) => {
          // var length = item.parkingRate.length - 1
          // var str = item.parkingRate.slice(0,length)
          // console.log('百分号前面')
          if (item.parkingRate !== null) {
            var index = item.parkingRate.indexOf(".");
            if (index >= 0) {
              //百分比有小数,需要保留一位小数
              item.parkingRate = item.parkingRate.slice(0, index + 2) + '%'
            }
          }
        });
        console.log("arr", arr);
        this.totalArr.push(arr);
        index += 9;
      }

      console.log("page", pageNum);

      console.log(this.totalArr);
    },
  },
};
</script>

<style scoped lang="less">
.mainCenterFather {
  height: 100%;

  //顶部标题
  .topPart {
    height: 43px;
    line-height: 63px;
    letter-spacing: 1px;
    color: #17caf0;
    font-size: 22px;
    text-align: center;
    font-family: '黑体';
    font-weight: bolder;

    .topPartText {
      line-height: 43px;
      // width: 200px;
      // height: 100%;
      // border: 2px solid #00caf0;
      // border-radius: 3px;
      // background: #004774;
    }
  }

  //中间图标
  .iconTitlePart {
    margin-top: 20px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;

    .firstIconBox,
    .secondIconBox,
    .thirdIconBox,
    .fouthIconBox {
      display: flex;
      align-items: center;

      .firstText,
      .secondText,
      .thirdText,
      .fouthText {
        margin-left: 10px;
        color: #356fcb;
        font-size: 22px;
        font-weight: 700;
      }
    }

    .firstIcon,
    .secondIcon,
    .thirdIcon,
    .fouthIcon {
      width: 30px;
      height: 30px;
    }
  }

  //底部主要内容
  .cartContainer {
    height: 480px;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row;
    padding: 5px 30px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  /deep/.carous {
    .slick-slider {
      .slick-dots {
        position: absolute;
        bottom: 0px !important;
      }
    }
  }
}
</style>
