<template>
  <div class="bottomRight">
    <div class="dv-bor-box">
      <div class="topTitleBox">停车场信息</div>
      <div class="bottomBox">
        <div class="topCarInformationBox">
          <div class="topLeftCarInformationBox">
            <img src="@/assets/大屏切图/总车位@2x.png" alt="" />
            <div class="titleData">
              <div class="titleBox">总车位数</div>
              <div class="dataBox">{{ totalSpaces }}个</div>
            </div>
          </div>
          <div class="topbottomCarInformationBox" style="margin-right: 20px">
            <img src="@/assets/大屏切图/空余车位@2x.png" alt="" />
            <div class="titleData">
              <div class="titleBox">空余车位</div>
              <div class="dataBox">{{ remainSpaces }}个</div>
            </div>
          </div>
        </div>
        <div class="bottomCarInformationBox">
          <div class="bottomLeftCarInformationBox">
            <img src="@/assets/大屏切图/在场车位@2x.png" alt="" />
            <div class="titleData">
              <div class="titleBox">在场总数</div>
              <div class="dataBox">{{ floorVehicleNums }}个</div>
            </div>
          </div>
          <div class="bottomRightCarInformationBox">
            <img src="@/assets/大屏切图/泊位车位@2x.png" alt="" />
            <div class="titleData">
              <div class="titleBox">泊位占用率</div>
              <div class="dataBox">{{ parkingRate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTotalParkMessage } from "../../../api/getTotalParkMessage/getTotalParkMessage.js";
export default {
  name: "bottomRight",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      totalSpaces: 0, //总车位数
      remainSpaces: 0, //空余车位
      floorVehicleNums: 0, //在场总数
      parkingRate: 0, //泊位占用率
    };
  },
  created() {
    this.getTotalParkMessageList();
  },
  methods: {
    async getTotalParkMessageList() {
      const res = await getTotalParkMessage(this.fkPlatTenantId);
      console.log("停车场信息", res);
      this.totalSpaces =
        res.data.totalSpaces == null ? 0 : res.data.totalSpaces;
      this.remainSpaces =
        res.data.remainSpaces == null ? 0 : res.data.remainSpaces;
      this.floorVehicleNums =
        res.data.floorVehicleNums == null ? 0 : res.data.floorVehicleNums;

      if (res.data.parkingRate !== null) {
        var index = res.data.parkingRate.indexOf(".");
        if (index >= 0) {
          //百分比有小数,需要保留一位小数
          this.parkingRate = res.data.parkingRate.slice(0, index + 2) + "%";
        } else {
          this.parkingRate = res.data.parkingRate;
        }
      }else{
        this.parkingRate = 0.00+'%'
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bottomRight {
  width: 24%;

  .dv-bor-box {
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 260px;
    margin-bottom: 5px;
    padding: 30px;
    .topTitleBox {
      font-size: 22px;
      font-weight: 700;
      color: #00caf0;
      margin-left: 10px;
    }
    .bottomBox {
      margin-top: 30px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .topCarInformationBox,
      .bottomCarInformationBox {
        display: flex;
        justify-content: space-between;
        .topLeftCarInformationBox,
        .topbottomCarInformationBox,
        .bottomLeftCarInformationBox,
        .bottomRightCarInformationBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .titleData {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .titleBox {
              font-size: 22px;
              color: #4185f0;
              font-weight: 700;
            }
            .dataBox {
              margin-top: 15px;
              color: #fff;
            }
          }
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
