<template>
  <div class="mainCenterChild">
    <div
      class="tianyuanText"
      style="color:#fbd618;font-size:20px;font-weight:700; white-space: nowrap;"
    >
      {{ parkLotName }}
    </div>
    <div class="tianyuanIconBox">
      <div class="LeftIconBox">
        <div class="FirstLeftIconBox">
          <img src="@/assets/大屏切图/总车位@2x.png" alt="" />
          <div class="FirstLeftText" style="color:white;">{{ totalSpaces ||0}}个</div>
        </div>
        <div class="SecondLeftIconBox">
          <img src="@/assets/大屏切图/在场车位@2x.png" alt="" />
          <div class="FirstLeftText" style="color:white;">{{ floorVehicleNums || 0 }}个</div>
        </div>
      </div>
      <div class="RightIconBox">
        <div class="FirstRightIconBox">
          <img src="@/assets/大屏切图/空余车位@2x.png" alt="" />
          <div class="FirstRightText" style="color:white;">{{ remainSpaces || 0 }}个</div>
        </div>
        <div class="SecondRightIconBox">
          <img src="@/assets/大屏切图/泊位车位@2x.png" alt="" />
          <div class="FirstRightText" style="color:white;">{{ parkingRate||0.00+'%'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainCenterChild",
  props: [
    "parkLotName",
    "totalSpaces",
    "remainSpaces",
    "floorVehicleNums",
    "parkingRate",
  ],
};
</script>

<style scoped lang="scss">
.mainCenterChild {
  width: 250px;
  // margin: 5px 0px 5px 23px;
  height: 132px;
  padding: 10px 20px;
  // border: 2px solid #164a9c;
  // border-radius: 6px;
  box-sizing: border-box;
  background-image: url(../../../assets/images/pic6.png);
  background-size: 100% 100%;
  .tianyuanIconBox,
  .chianIconBox,
  .nanlinfangIconBox,
  .zhengwuIconBox,
  .yungusxIconBox,
  .nanlinchangIconBox,
  .yungutiyuIconBox,
  .shuizhiIconBox {
    margin-top: 10px;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    .LeftIconBox,
    .RightIconBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .FirstLeftIconBox,
      .SecondLeftIconBox,
      .FirstRightIconBox,
      .SecondRightIconBox {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
      .FirstLeftIconBox,.FirstRightIconBox{
        margin-bottom: 8px;
      }
    }
    .bottomIconBox {
      // margin-top: 10px;
    }
  }
}
</style>
