<template>
  <div class="bottomChild">
    <div class="dv-bor-13">
      <div class="mainBox">
        <div class="topPart">24小时进出场流量</div>
        <div class="bottomPart" id="myChart" :style="{ width: '430px', height: '280px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataVisualizationTodayRecordFlow } from "../../../api/dataVisualizationTodayRecordFlow/dataVisualizationTodayRecordFlow.js";
export default {
  name: "bottomChild",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      Invalue: [],
      Outvalue: [],
    };
  },
  created() {
    this.dataVisualizationTodayRecordFlowList();
  },
  mounted() {
    this.initMyEchart();
  },
  watch: {
    Invalue() {
      this.initMyEchart();
    },
    Outvalue() {
      this.initMyEchart();
    },
  },
  methods: {
    async dataVisualizationTodayRecordFlowList() {
      const res = await dataVisualizationTodayRecordFlow(this.fkPlatTenantId);
      console.log("24小时进出场流量折线图数据", res);
      let Invalue1 = 0;
      let a1 = res.data.in.slice(0, 8);
      for (var i = 0; i < a1.length; i++) {
        Invalue1 += Number(a1[i]);
      }

      let Invalue2 = 0;
      let a2 = res.data.in.slice(8, 16);
      for (var j = 0; j < a2.length; j++) {
        Invalue2 += Number(a2[j]);
      }

      let Invalue3 = 0;
      let a3 = res.data.in.slice(16);
      for (var k = 0; k < a3.length; k++) {
        Invalue3 += Number(a3[k]);
      }

      let isInZero = Invalue1 === 0 && Invalue2 === 0 && Invalue3 === 0
      if (!isInZero) {
        this.Invalue = [Invalue1, Invalue2, Invalue3];
      } else {
        this.Invalue = []
      }
      let Outvalue1 = 0;
      let b1 = res.data.out.slice(0, 8);
      for (var l = 0; l < b1.length; l++) {
        Outvalue1 += Number(b1[l]);
      }

      let Outvalue2 = 0;
      let b2 = res.data.out.slice(8, 16);
      for (var m = 0; m < b2.length; m++) {
        Outvalue2 += Number(b2[m]);
      }

      let Outvalue3 = 0;
      let b3 = res.data.out.slice(16);
      for (var n = 0; n < b3.length; n++) {
        Outvalue3 += Number(b3[n]);
      }
      let isOutZero = Outvalue1 === 0 && Outvalue2 === 0 && Outvalue3 === 0
      if (!isOutZero) {
        this.Outvalue = [Outvalue1, Outvalue2, Outvalue3];
      } else {
        this.Outvalue = []
      }
      console.log(this.Outvalue);
    },
    //初始化echarts图表
    initMyEchart() {
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["00:00-08:00", "08:00-16:00", "16:00-24:00"],
          axisLabel: {
            show: true,
            textStyle: {
              color: "white", //更改坐标轴文字颜色
              fontSize: 10, //更改坐标轴文字大小
            },
          },
        },
        legend: {
          data: ["进场流量", "出场流量"],
          bottom: 5,
          textStyle: {
            fontSize: 14, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "item",
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "white", //更改坐标轴文字颜色
              fontSize: 10, //更改坐标轴文字大小
            },
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: "进场流量",
            data: this.Invalue,
            type: "line",
            areaStyle: {//区域填充渐变颜色
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(43, 254, 192, 0.5)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(43, 254, 192, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(43, 254, 192, 1)', //改变折线点的颜色
                lineStyle: {
                  color: 'rgba(43, 254, 192, 1)', //改变折线颜色
                },
              },
            },
            smooth: true,
          },
          {
            name: "出场流量",
            data: this.Outvalue,
            type: "line",
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(34, 66, 137, 0.5)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(34, 66, 137, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(34, 66, 137, 1)', //改变折线点的颜色
                lineStyle: {
                  color: 'rgba(34, 66, 137, 1)', //改变折线颜色
                },
              },
            },
            smooth: true,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomChild {
  .dv-bor-13 {
    // margin-top: 10px;
    // margin-bottom: 10px;
    padding: 20px;
    padding-top: 30px;
    height: 305px;
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .mainBox {
      position: relative;

      .topPart {
        font-size: 22px;
        font-weight: 700;
        color: #00caf0;
      }

      .bottomPart {
        margin-top: 10px;
        position: absolute;
        top: -25px;
        left: 0px;
      }
    }
  }
}
</style>
