import service from "../../utils/request";

//获取监控视频
export const getTreeList=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getTreeList',
        params:{
            fkPlatTenantId
        }
    })
}