<template>
  <div class="bottomLeft">
    <div class="dv-bor-box">
      <div class="borderTop">停车类型分布</div>
      <div class="borderBottom">
        <div class="leftDatavBox">
          <div class="title title1">临时车</div>
          <div class="circle circle1">
            {{ config1.data[0] }}
          </div>
          <!-- <dv-water-level-pond
            :config="config1"
            style="width:80px;height:90px"
          /> -->
        </div>
        <div class="rightDatavBox">
          <div class="title title2">固定车</div>
          <!-- <dv-water-level-pond
            :config="config2"
            style="width:80px;height:90px"
          /> -->
          <div class="circle circle2">
            {{ config2.data[0] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stopCarType } from "../../../api/stopCarType/stopCarType.js";
export default {
  name: "bottomLeft",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      config1: {
        data: [0],
        shape: "roundRect",
        formatter: '{value}%',
      },
      config2: {
        data: [0],
        shape: "roundRect",
        formatter: '{value}%',
      },
    };
  },
  created() {
    this.stopCarTypeList();
  },
  methods: {
    async stopCarTypeList() {
      const res = await stopCarType(this.fkPlatTenantId);
      let tem = Number(res.data.temporary)
      let fixed = Number(res.data.fixed)
      let total = Number(res.data.total)
      console.log(tem+''+fixed+''+total)
      this.config1.data[0] = (tem/total*100).toFixed(0)
      this.config2.data[0] = 100 - (tem/total*100).toFixed(0)
      this.config1={...this.config1}
      this.config2={...this.config2}
      console.log('停车类型分布',res)
      console.log(this.config2.data[0])
      console.log(this.config1.data[0])
    },
  },
};
</script>

<style scoped lang="scss">
.bottomLeft {
  width: 24%;
  .dv-bor-box {
    height: 260px;
    margin-bottom: 5px;
    padding: 30px;
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .borderTop {
      font-size: 22px;
      font-weight: 700;
      color: #00caf0;
    }
    .borderBottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding: 10px 50px 10px 60px;
      height: 140px;
      .leftDatavBox,
      .rightDatavBox {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
.title {
  font-size: 18px;
  position: relative;
}
.circle {
  font-size: 32px;
  width: 94px;
	height: 94px;
  text-align: center;
  line-height: 94px;
  margin-top: 20px;
  background-repeat: no-repeat;
}
.title::after {
  content: '';
  width: 16px;
	height: 16px;
	background-color: #fd786a;
  position: absolute;
  left: -20px;
  border-radius: 50%;
}
.title2::after {
	background-color: #fd786a;
}
.title1::after {
	background-color: #01faf2;
}
.circle2 {
  background-image: url(../../../assets/images/pic8.png);
  color: #fd7e6b;
}
.circle1 {
  background-image: url(../../../assets/images/pic7.png);
  color: #5fbafd;
}
</style>
