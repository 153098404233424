<template>
  <div class="mainTopChild">
    <div class="dv-bor-13">
      <div class="mainBox">
        <div class="topPart">
          <div class="topPartLeft">
            <div class="topPartLeftTitle">交易</div>
            <div class="topPartLeftData">
              <span v-for="(item, index) in number1" :key="index" class="led-font">
                {{ item }}
              </span>
            </div>
          </div>
          <div class="topPartRight">
            <div class="topPartRightTitle">收入</div>
            <div class="topPartLeftData">
              <span v-for="(item, index) in number2" :key="index" class="led-font">
                {{ item }}
              </span>
            </div>
          </div>
        </div>
        <div class="bottomPart">
          <dv-scroll-board :config="config3" class="dv-scr-bo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTodayParkCharge } from "../../../api/getTodayParkCharge/getTodayParkCharge.js";
export default {
  name: "mainTopChild",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      todayCarIncomeList: [],
      config1: {
        number: [0],
        content: "{nt}",
      },
      config2: {
        number: [0],
        content: "{nt}",
      },
      config3: {
        data: [
         
        ],
        columnWidth: [180, 80, 80],
        align:['center','center','center']
      },
      number1: [],
      number2: []
    };
  },
  created() {
    this.getTodayParkChargeList();
  },
  methods: {
    async getTodayParkChargeList() {
      const res = await getTodayParkCharge(this.fkPlatTenantId);
      console.log("今日车场收入", res);
      const dealNums =
        res.data.countNums.dealNums == null ? 0 : res.data.countNums.dealNums;
      const incomeNums =
        res.data.countNums.incomeNums == null
          ? 0
          : res.data.countNums.incomeNums;
      this.$set(this.config1, "number", [Number(dealNums)]);
      this.config1 = { ...this.config1 };
      this.$set(this.config2, "number", [Number(incomeNums)]);
      this.config2 = { ...this.config2 };
      this.number1 = (Number(dealNums) + '').split('');
      this.number2 = (Number(incomeNums) + '').split('');

      let todayIncomeArr = [];
      let todayArr = [];
      for (var i = 0; i < res.data.list.length; i++) {
        todayArr = [];
        todayArr.push(res.data.list[i].lotName);
        todayArr.push(res.data.list[i].dealNums);
        todayArr.push(res.data.list[i].incomeNums);
        todayIncomeArr.push(todayArr);
      }
      this.$set(this.config3, "data", todayIncomeArr);
      this.config3 = { ...this.config3 };
      console.log(todayIncomeArr);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainTopChild {
  .dv-bor-13 {
    height: 315px;
    padding: 20px;
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .mainBox {
      height: 270px;
      display: flex;
      flex-direction: column;
      .topPart {
        height: 48%;
        padding: 20px 50px 0 50px;
        display: flex;
        justify-content: space-between;
        .topPartLeft,
        .topPartRight {
          width: 50%;
          padding: 20px 0 10px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: space-between;
          font-size: 22px;
          letter-spacing: 3px;
          font-weight: 700;
          color: #00caf0;
        }
      }
      .bottomPart {
        height: 52%;
        .dv-scr-bo {
          margin-bottom: 40px;
        }
      }
    }
  }
}
.topPartLeftData,.topPartRightData {
  margin-top: 20px;
}
</style>
