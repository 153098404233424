import service from "../../utils/request";

//今日进出场车辆
export const getTodayRecordInOut=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getTodayRecordInOut',
        params:{
            fkPlatTenantId
        }
    })
}