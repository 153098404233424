import service from "../../utils/request";

//停车类型发布
export const stopCarType=(fkPlatTenantId)=>{
    return service({
        method:'GET',
        url:'/dxpark/wypark/dataVisualization/getParkingTypeDistribution',
        params:{
            fkPlatTenantId
        }
    })
}