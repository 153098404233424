<template>
  <div class="mainRightFather">
    <main-top-child></main-top-child>
    <main-bottom-child></main-bottom-child>
  </div>
</template>

<script>
import mainTopChild from "./mainTopChild.vue";
import mainBottomChild from "./mainBottomChild.vue";
export default {
  name: "mainRightFather",
  components: {
    mainTopChild,
    mainBottomChild,
  },
};
</script>

<style lang="scss" scoped>
.mainRightFather {
  height: 100%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  // padding: 10px;
}
</style>
