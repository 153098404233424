<template>
  <div class="mainBottomChild">
    <div class="dv-bor-13">
      <div class="mainBox">
        <div class="topPart">24小时收入统计</div>
        <div class="bottomPart" id="myChart2" :style="{ width: '400px', height: '260px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHoursParkCharge } from "../../../api/getHoursParkCharge/getHoursParkCharge.js";
export default {
  name: "mainBottomChild",
  data() {
    return {
      fkPlatTenantId: "ff8080817b7c8ff6017b7ca9a1d900d6",
      value: [],
    };
  },
  created() {
    this.getHoursParkChargeList();
  },
  mounted() {
    this.initMyEchart();
  },
  watch: {
    value() {
      this.initMyEchart();
    },
  },
  methods: {
    async getHoursParkChargeList() {
      const res = await getHoursParkCharge(this.fkPlatTenantId);
      console.log("24小时收入统计", res);
      let value1 = 0;
      let a1 = res.data.slice(0, 6);
      for (var i = 0; i < a1.length; i++) {
        value1 += Number(a1[i].incomeNums);
      }

      let value2 = 0;
      let a2 = res.data.slice(6, 12);
      for (var j = 0; j < a2.length; j++) {
        value2 += Number(a2[j].incomeNums);
      }

      let value3 = 0;
      let a3 = res.data.slice(12, 18);
      for (var k = 0; k < a3.length; k++) {
        value3 += Number(a3[k].incomeNums);
      }

      let value4 = 0;
      let a4 = res.data.slice(18);
      for (var l = 0; l < a4.length; l++) {
        value4 += Number(a4[l].incomeNums);
      }

      this.value = [value1, value2, value3, value4];
    },
    initMyEchart() {
      let myChart2 = this.$echarts.init(document.getElementById("myChart2"));
      myChart2.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "0%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["00:00-06:00", "06:00-12:00", "12:00-18:00", "18:00-24:00"],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "white", //更改坐标轴文字颜色
                fontSize: 10, //更改坐标轴文字大小
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "white", //更改坐标轴文字颜色
                fontSize: 12, //更改坐标轴文字大小
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "50%",
            data: this.value,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(253, 126, 107, 0.7)'
                }, {
                  offset: 1,
                  color: 'rgba(253, 126, 107, 0.3)'
                }]),
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "white",
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mainBottomChild {
  .dv-bor-13 {
    padding: 20px;
    padding-top: 30px;
    height: 305px;
    background-image: url(../../../assets/images/pic3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .mainBox {
      position: relative;

      .topPart {
        font-size: 22px;
        font-weight: 700;
        color: #00caf0;
      }

      .bottomPart {
        margin-top: 10px;
        position: absolute;
        top: -25px;
        left: -10px;
      }
    }
  }
}
</style>
